<template>
  <div>
    <CRow>
      <CCol lg="12">
          <!-- <addContent/> -->
          <studyContent1/>
      </CCol>
      <!-- <CCol lg="4">
        <CloudStorageChart />
        <QuickNoteForm />
      </CCol> -->
    </CRow>
  </div>
</template>

<script>
import WidgetStats from "../app-main-views/stats-widgets/WidgetStats";
import UserProfileCard from "../app-main-views/user-profile-card/UserProfileCard";
import CloudStorageChart from "../app-main-views/cloud-storage-chart/CloudStorageChart";
import QuickNoteForm from "../app-main-views/forms/QuickNoteForm";
import addContent from "../../components/add_contents.vue";
import studyContent1 from "../../components/study_content1.vue";

export default {
  name: "Dashboard",
  data(){
    return{
       url_flag: ""
    }
     
  },
  
  components: {
    WidgetStats,
    UserProfileCard,
    studyContent1,
    CloudStorageChart,
    QuickNoteForm,
    addContent
  },
  created(){
    let user_id = this.$session.get('user_id');
    let user_role_id = this.$session.get('user_role_id');
    //  let uri = window.location.href.split('?');
     /// console.log("here");
      //console.log(this.$route.path);
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    }
    if(user_role_id != '8290f938-b20a-42e3-c201-08d8f3241655')
    {
      return '/';
    }
    
    // if (uri.length == 2)
    // {
    //   let vars = uri[1].split('&');
    //   let getVars = {};
    //   let tmp = '';
    //   vars.forEach(function(v){
    //     tmp = v.split('=');
    //     if(tmp.length == 2)
    //     getVars[tmp[0]] = tmp[1];
    //   });
    //   console.log(getVars);
    //   // do 
    // }
          
  }
};
</script>
